import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58')
];

export const server_loads = [0];

export const dictionary = {
		"/(admin)/[[region=regionCode]]/admin": [11,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/analytics": [12,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/analytics/events": [14,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/analytics/[startDate]/[endDate]/[[category]]": [13,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/event": [15,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/event/new": [19,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/event/[event]": [16,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/event/[event]/new-ticket": [17,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/event/[event]/setup/[[tab]]": [18,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/membership": [20,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/membership/new": [25,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/membership/subscription/[sub]": [26,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/membership/[membership]": [21,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/membership/[membership]/invite": [22,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/membership/[membership]/invite/new": [23,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/membership/[membership]/setup/[[tab]]": [24,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/orders": [27,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/orders/[order]": [28,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/pricing/[pricing]": [29,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/profile": [30,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/ticket/[ticket]": [31,[2],[3]],
		"/(admin)/[[region=regionCode]]/admin/ticket/[ticket]/setup": [32,[2],[3]],
		"/(app)/[[region=regionCode]]/calendar": [33,[4],[5]],
		"/(app)/[[region=regionCode]]/checkout": [34,[4],[5]],
		"/(app)/[[region=regionCode]]/dbg": [35,[4],[5]],
		"/(app)/[[region=regionCode]]/dbg/throw-500": [36,[4],[5]],
		"/(app)/de": [46,[4,8],[5]],
		"/(app)/de/for-organisers": [47,[4,8],[5]],
		"/(app)/de/for-organisers/tos": [48,[4,8],[5]],
		"/(app)/en": [49,[4,9],[5]],
		"/(app)/en/for-organisers": [50,[4,9],[5]],
		"/(app)/en/for-organisers/tos": [51,[4,9],[5]],
		"/(app)/en/privacy": [52,[4,9],[5]],
		"/(app)/en/tos": [53,[4,9],[5]],
		"/(app)/[[region=regionCode]]/e": [37,[4,6],[5]],
		"/(app)/[[region=regionCode]]/e/[event]": [38,[4,6],[5]],
		"/(app)/hu": [54,[4,10],[5]],
		"/(app)/hu/for-organisers": [55,[4,10],[5]],
		"/(app)/hu/for-organisers/tos": [56,[4,10],[5]],
		"/(app)/hu/privacy": [57,[4,10],[5]],
		"/(app)/hu/tos": [58,[4,10],[5]],
		"/(app)/[[region=regionCode]]/my/orders": [40,[4,7],[5]],
		"/(app)/[[region=regionCode]]/my/orders/[order]": [41,[4,7],[5]],
		"/(app)/[[region=regionCode]]/my/subscriptions": [42,[4,7],[5]],
		"/(app)/[[region=regionCode]]/my/subscriptions/[sub]": [43,[4,7],[5]],
		"/(app)/[[region=regionCode]]/m/[membership]/[[invite]]": [39,[4],[5]],
		"/(app)/[[region=regionCode]]/o/[organiser]/tos": [45,[4],[5]],
		"/(app)/[[region=regionCode]]/o/[organiser]/[[category]]": [44,[4],[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';